<!--
 * @Author: linjituan linjituan@revolution.com
 * @Date: 2023-10-18 10:25:59
 * @LastEditors: linjituan linjituan@revolution.com
 * @LastEditTime: 2023-10-18 16:13:48
 * @FilePath: \ra-web-admin\src\views\monitor\monitorForm\vehicle\funcList\modules\baiduRtc\BaiduRtcTest.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <a-button @click="handleComeIn('therevideo', '0')">进入房间1</a-button>
    <a-button @click="handleComeIn('therevideo2', '1')">进入房间2</a-button>
    <a-button @click="handleComeIn('therevideo3', '2')">进入房间3</a-button>
    <div style="display: flex;">
      <div style="flex: 1" id="therevideo"></div>
      <div style="flex: 1" id="therevideo2"></div>
      <div style="flex: 1" id="therevideo3"></div>
    </div>
  </div>
</template>
<script>
import RtcHelper from './BRtcHelper'
export default {
  mixins: [RtcHelper],
  data() {
    return {
      brtc: {
    'roomName': 'test02',
    'appId': 'apppgimhuxdztex',
    'userTokens': [
        {
            'userId': 100006,
            'token': '00473499951c8f1aebccbadee95b4a2237578a0c0d416976091954aefd5fd1698213995'
        },
        {
            'userId': 200006,
            'token': '0047a8d948c5ed42b5056b2e1058b6fd1cada47af4e169760919569d715101698213995'
        },
        {
            'userId': 300006,
            'token': '004fca5748d470e2b6ea4cabee4baa91f7334f8cbe1169760919592f91f881698213995'
        }
    ]
}
    }
  },
  mounted() {
  },
  methods: {
    handleComeIn(id, index) {
      this.start(id, index)
    }
  }
}
</script>
